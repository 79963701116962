<template>
  <div class="about">
    <div>
      <div>
        <h2>Абакус</h2><br>
      </div>
      <div>
        За ученике 1. и 2. разреда ОШ<br><br>
      </div>
      <div>
        Аутор: Предраг Влајковић<br>
        <br>
        Посвећено мом сину Огњену, са жељом да буде успешан математичар<br><br>
      </div>
    </div>
    <br>
    <img src="/assets/poster.png" alt="Ognjen">
    <br>
  </div>
</template>

<script>
export default {
  name: "About",
  data () {
    return {
      title: "О нама",
      version: "dev-9"
    }
  },
}
</script>
